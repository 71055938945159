import {Button, Flex, Input, Space, Tag, Select, Modal} from "antd";
import {SendOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {isNil, trim} from "lodash";
import {PracticeMessageState} from "../../../models/Practice/PracticeMessage";
import {PracticeDetail, PracticeProgress} from "../../../models/Practice/PracticeDetail";
import {PracticeProgressStatus} from "../../../models/Practice/Practice";
import {useTranslation} from "react-i18next";
import './MessageTextBox.css'
import {Utils} from "../../../services/Utils";
import {claimClosed, useClaimStatus} from "../../PracticesDetails/ClaimStatusProvider";
import {AuthService} from "../../../services/AuthService";
import {Employee} from "../../../models/Auth/Employee";
import {PracticesHttpService} from "../../../services/Http/PracticesHttpService";

export interface MessageTextBoxProps{
    isNote?: boolean;
    onClickButton:(from:string, messageText:string, state:PracticeMessageState|undefined)=>void
    showState?:boolean
    showSendFrom?:boolean;
    disable?:boolean;
    isMandatoryStatus?:boolean
    onClickLabel?:(labelType:PracticeMessageState)=>void;
    progress?: PracticeProgress[]
    practiceDetail?: PracticeDetail;
    reloadDetail?: () => void;
}
export function MessageTextBox(props:MessageTextBoxProps){
    const claimStatus = useClaimStatus();
    const { t } = useTranslation();
    const [from,setFrom] = useState<string>("");
    const [messageText,setMessageText] = useState<string>("");
    const [isDisableButton,setIsDisableButton] = useState<boolean>((props?.isMandatoryStatus || claimClosed(claimStatus)) ? true : false);
    const [state,setState] = useState<PracticeMessageState|undefined>(PracticeMessageState.STATELESS);
    const [selectedMessage, setSelectedMessage] = useState<string | undefined>();
    const [selectedProgress, setSelectedProgress] = useState<PracticeProgressStatus | undefined>();

    let precompiledMessages = [
        { title: 'No Polizza / No Garanzia', text: 'Buongiorno, il cliente non risulta aver diritto al rimborso in forma diretta con {ENTE}. L\'eventuale acquisto sarà quindi interamente a carico del cliente.\n' +
                '\n' +
                '- Avvisare il cliente invitandolo a contattare la sua assicurazione per maggiori dettagli e procedere con la chiusura della pratica indicando l’eventuale acquisto/non acquisto.\n' +
                '\n' +
                'Per supporto e assistenza contattare convenzioni@salmoiraghievigano.it e support@manydesigns.com', progress: PracticeProgressStatus.PIC_NON_AUTORIZZATA},
        { title: 'Massimale esaurito', text: 'Buongiorno, il cliente risulta aver esaurito il massimale con {ENTE}. \n' +
                'L\'eventuale acquisto sarà quindi interamente a carico del cliente.\n' +
                '\n' +
                '- Avvisare il cliente e procedere con la chiusura della pratica indicando l’eventuale acquisto/non acquisto.\n' +
                '\n' +
                'Per supporto e assistenza contattare convenzioni@salmoiraghievigano.it e support@manydesigns.com', progress: PracticeProgressStatus.PIC_NON_AUTORIZZATA},
        { title: 'Pic non autorizzata', text: 'Buongiorno, la PIC non è stata autorizzata. L\'eventuale acquisto sarà quindi interamente a carico del cliente.\n' +
                '\n' +
                '- Avvisare il cliente invitandolo a contattare la sua assicurazione per maggiori dettagli e procedere con la chiusura della pratica indicando l’eventuale acquisto/non acquisto.\n' +
                '\n' +
                'Per supporto e assistenza contattare convenzioni@salmoiraghievigano.it e support@manydesigns.com', progress: PracticeProgressStatus.PIC_NON_AUTORIZZATA},
        { title: 'Pic annullata', text: 'Buongiorno, la PIC è stata annullata.\n' +
                '\n' +
                '- Contattare convenzioni@salmoiraghievigano.it indicando la motivazione (PIC annullata) per capire come proseguire con la pratica.\n' +
                '\n' +
                'Per supporto e assistenza contattare convenzioni@salmoiraghievigano.it e support@manydesigns.com', progress: PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE},
        { title: 'Fattura errata', text: 'Buongiorno, il totale fattura o le quote in fattura non corrispondono alla PIC. Siete pregati di stornare la fattura e inserire quella corretta dopo aver eliminato il vecchio documento tramite il cestino.\n' +
                '\n' +
                'Inoltre, vi ricordiamo che: \n' +
                '- Gli scontrini non hanno valenza fiscale; \n' +
                '- La fattura deve contenere la quota ente e la quota cliente. Non deve riportare un\'unica voce su "Caparra" ma deve uscire con gli importi allocati correttamente per un tema contabile (GBS non riconcilierà mai e rischiate l’ammanco cassa); \n' +
                '- La documentazione deve essere inserita negli appositi pulsanti. I documenti inseriti in "Aggiungi documento" (altro) non vengono tenuti in considerazione.\n' +
                '\n' +
                'Per supporto e assistenza contattare convenzioni@salmoiraghievigano.it  e support@manydesigns.com', progress: PracticeProgressStatus.ERRORE_IN_FATTURA},
    ];
    const clientNonTrovatoProviders = ['BLUE', 'CASP', 'GRUPPOGENERALI','POSTE', 'POSTEWELFARE', 'INSALUTE'];
    if (props.practiceDetail?.insuranceProvider.code && clientNonTrovatoProviders.includes(props.practiceDetail?.insuranceProvider.code)) {
        const clienteNonTrovatoOption = { title: 'Cliente non trovato', text: 'Buongiorno, il cliente indicato non ci risulta essere un assistito {ENTE}, Verificare che i dati del cliente/assicurazione riportati nel voucher identificativo siano corretti, in particolare:\n' +
                '\n' +
                '- Nome, Cognome e Codice Fiscale del cliente corretti \n' +
                '- Partner assicurativo indicato corretto\n ' +
                '\n' +
                '- Se errati: provvedere a chiudere la pratica e ad aprirne una nuova con i dati corretti (Nome, Cognome, Codice Fiscale e assicurazione).\n' +
                (['CASP', 'GRUPPOGENERALI'].includes(props.practiceDetail?.insuranceProvider.code) ?
                    '- Se corretti: Il cliente non ha copertura assicurativa, procedere con la chiusura della pratica indicando l’eventuale acquisto/non acquisto.\n' :
                    '- Se corretti: Inviate a convenzioni@salmoiraghievigano il voucher identificativo del cliente così da poter verificare con il partner.\n') +
                '\n' +
                'Per supporto e assistenza contattare convenzioni@salmoiraghievigano.it e support@manydesigns.com', progress: PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE}
        precompiledMessages = [clienteNonTrovatoOption, ...precompiledMessages];
    }

    const onChangeSelect = (value: string) => {
        if (value === '') {
            setMessageText('');
            setSelectedMessage(undefined);
            setSelectedProgress(undefined);
        } else {
            const selected = precompiledMessages.find((msg) => msg.title === value);
            if (selected) {
                const ente = props.practiceDetail?.insuranceProvider.description;
                const regex = new RegExp('{ENTE}', 'g');
                const testo = ente ? selected.text.replace(regex, ente) : selected.text;
                setMessageText(testo);
                setSelectedProgress(selected.progress);
            }
            setSelectedMessage(value);
        }
    };

    function onChangeFrom(element:any){
        setFrom(element.target.value);
        disableButton(messageText,element.target.value,state);
    }

    function onChangeMessageText(element:any){
        setMessageText(element.target.value);
        disableButton(element.target.value,from,state);
    }

    const onClickButton = async () => {
        const id = props.practiceDetail?.id.toString();
        if (id && selectedProgress) {
            let operator:Employee|null = AuthService.getOperatorOnline();
            try {
                await PracticesHttpService.saveForceProceedNext(id, {
                    metadata: { operator:operator?.name ?? "" },
                    event: selectedProgress
                })
                props.reloadDetail?.();
            } catch (e) {
                console.error(e);
            }
        }
        props.onClickButton(from,messageText,state);
        setMessageText("");
        setSelectedMessage(undefined);
        setSelectedProgress(undefined);
        setFrom("")
        setState(undefined)
        disableButton("","",undefined);
        console.log('Messaggio inviato:', messageText);
    };

    function disableButton(text:string,from:string, state:PracticeMessageState|undefined){
        if(props.isMandatoryStatus){
            if( trim(text) === "" || (props.showSendFrom === true && trim(from) === "") || (isNil(state) && props.showState) || props.disable){
                setIsDisableButton(true);
            } else {
                setIsDisableButton(false);
            }
        }

    }
    function getSelectedStateColor(isDisabled: boolean, elem : PracticeMessageState){
        if (isDisabled) {
            return "gray";
        }

        if (elem === state ){
            return "green"
        }
        return ""
    }

    function getStateLabels() {
        return Object.keys(PracticeMessageState).map((key:string)=> {
            // FIXME
            //@ts-ignore
            const practiceProgressStatus = PracticeProgressStatus[key];
            if (isNil(practiceProgressStatus)) {
                return;
            }
            const isDisabled = Utils.isStatusInProgress(practiceProgressStatus, props.progress);
            const className = "state-label"+ (isDisabled ? " state-label-disabled" : "")

            if(key !== "STATELESS"){
                let elem : PracticeMessageState = PracticeMessageState[key as keyof typeof PracticeMessageState];
                return <Tag className={className}
                            color={getSelectedStateColor(isDisabled, elem)}
                            onClick={()=>{
                                if (isDisabled) return;

                                onClickState(elem);
                            }}>
                    {t(key)}
                </Tag>
            }
            return;
        })
    }
    function onClickState(elem : PracticeMessageState ){
        if(elem === state){
            setState(PracticeMessageState.STATELESS)
        } else {
            setState(elem);
        }

        disableButton(messageText,from,elem);

        if(props?.onClickLabel){
            props?.onClickLabel(elem);
        }
    }
    return <>
        <Space direction="vertical" >
            <Flex justify={'space-between'}>
                {props.showState === true && props?.disable === false && getStateLabels()}
            </Flex>

            { props.showSendFrom === true && <Input placeholder="Inviato da" className={"width-100"} onChange={onChangeFrom}/>}

            {
                AuthService.isAdmin() && props.isNote && <Select
                    placeholder="Seleziona un messaggio precompilato"
                    className="width-100"
                    value={selectedMessage}
                    onChange={onChangeSelect}
                    options={[
                        { label: 'Nessuna selezione', value: '' }, // Opzione per il reset
                        ...precompiledMessages.map((msg) => ({
                        label: msg.title,
                        value: msg.title,
                    }))
                ]}
                />
            }

            <Space.Compact className={"width-100"}>
                <Input.TextArea placeholder="Digita il testo" value={messageText} onChange={onChangeMessageText}
                                disabled={props.disable || claimClosed(claimStatus)}
                                autoSize={{ minRows: 3, maxRows: 3 }}
                                style={{ resize: 'none' }}
                />
                <Button style={{ height: 'auto' }} icon={<SendOutlined />} onClick={onClickButton} disabled={isDisableButton}/>
            </Space.Compact>
        </Space>

    </>
}